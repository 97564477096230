import React from "react";
import fs from "../../../images/vacancy/frontend.inline.svg";
import Sale from "../../../images/vacancy/sales.inline.svg"
import graphicDesign from "../../../images/vacancy/designer.inline.svg"
import qa from "../../../images/vacancy/qa.inline.svg"
import pm from "../../../images/vacancy/projMan.inline.svg"
import mobile from "../../../images/vacancy/mobile.inline.svg"
import dm from "../../../images/vacancy/dm.inline.svg"
import backend from "../../../images/vacancy/backend.inline.svg"
import devops from '../../../images/vacancy/sysadmin.inline.svg'
import frontend from '../../../images/vacancy/frontend.inline.svg'
import HR from '../../../images/vacancy/HR.inline.svg'
import tc from '../../../images/vacancy/tc.inline.svg'
import all from '../../../images/vacancy/all.inline.svg'
import businessAnalysis from '../../../images/vacancy/business-analytic.inline.svg'
import desktop from '../../../images/vacancy/desktop.inline.svg'

const ImgComponentsMap = {
  all,
  fs,
  desktop,
  Sale,
  graphicDesign,
  qa,
  pm,
  mobile,
  dm,
  businessAnalysis,
  backend,
  devops,
  frontend,
  HR,
  tc,
}

const constructProps = (isActive) => ({
  className: isActive ? "icon" : "not-available",
  alt:"pic for profession"
})

export const renderIconFactory = ({key, isActive}) => {
  const Component = ImgComponentsMap[key]
  return <Component {...constructProps((isActive))}/>
}
