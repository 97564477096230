import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { globalHistory } from '@reach/router';
import VacancyItem from '../components/VacanciesPageComponents/VacancyItem';
import HotVacancyItem from '../components/VacanciesPageComponents/HotVacancyItem';
import VacancyBox from '../components/VacanciesPageComponents/VacancyBox';
import OpenImg from '../images/vacancy/open.inline.svg';
import CloseImg from '../images/vacancy/close.inline.svg';
import { windowSizeChecker } from '../utils';
import SliderUniversal from '../components/Slider';
import DeviceDetect from "../components/DeviceDetect";

function returnTypesOfActiveVacancies(data) {
  const typesActiveVacancies = new Set();
  data.vacancies_list.map((vacancy) => {
    typesActiveVacancies.add(vacancy.vacancy_category.Category);
  });
  return typesActiveVacancies;
}

const Vacancy = ({ pageContext: { data, common_data } }) => {
  const showAllType = 'All categories';
  const [chosenVacationCategory, setChosenVacationCategory] = useState(showAllType);
  const [lang, setLange] = useState('');
  const [vacanciesFilter, setVacanciesFilter] = useState([]);
  const [toggleBox, setToggle] = useState(false);

  const vacationList = data?.vacancies_list;
  const typesActiveVacancies = returnTypesOfActiveVacancies(data);

  const {isMobile, isTablet, isBigTablet} = windowSizeChecker;

  useEffect(() => {
    setLange(globalHistory.location.pathname.split('/')[1]);
    common_data?.filter_vacancies &&
      setVacanciesFilter(
        common_data.filter_vacancies.sort(({ title }) =>
          typesActiveVacancies.has(title.Category) ? -1 : 1
        )
      );
  }, []);

  const showHotVacancies = () => {
    return vacationList.find((item) => {
      if (
        item?.isHotVacancy
        && (item.vacancy_category.Category === chosenVacationCategory
        || chosenVacationCategory === showAllType)
      ){
        return true;
      }
    });
  };

  const handleClickMoreVacancy = () => {
    !toggleBox ? setToggle(true) : setToggle(false);
  };

  const renderFilterVacancy = () => {
    if (!vacanciesFilter) return [];
    let skipIdx = vacanciesFilter.length;
    if (!toggleBox) {
      skipIdx = 9;
      if (isMobile()) skipIdx = 3;
      if (isTablet()) skipIdx = 5;
      if (isBigTablet()) skipIdx = 7;

    }
    const _createVacancy = (item, idx) => {
      return (
        <VacancyBox
          vacancy={item}
          typesActiveVacancies={typesActiveVacancies}
          chosenVacationType={chosenVacationCategory}
          setChosenVacancyType={setChosenVacationCategory}
          url={`/${lang}/vacancies/vacancy/${idx}`}
          key={item?.id}
        />
      );
    };
    return vacanciesFilter
      .slice(0, skipIdx)
      .map((item, idx) => _createVacancy(item, idx));
  };

  const renderVacancyFilterSlider = () => {
    let sortable = vacanciesFilter.sort(({ isActive, title }) =>
      isActive && title.Category === showAllType ? -1 : 1
    );
    const arrVacancyComponent = sortable.map((item, idx) => {
      return (
        <VacancyBox
          vacancy={item}
          typesActiveVacancies={typesActiveVacancies}
          chosenVacationType={chosenVacationCategory}
          setChosenVacancyType={setChosenVacationCategory}
          url={`/${lang}/vacancies/vacancy/${idx}`}
          key={item?.id}
        />
      );
    });

    return (
      <SliderUniversal numberOfSlides={2} children={arrVacancyComponent} />
    );
  };

  const renderHotVacancySlider = () => {
    const vacancyCards = vacationList.map((item) => {
      if (
        item?.isHotVacancy
        && (item.vacancy_category.Category === chosenVacationCategory
        || chosenVacationCategory === showAllType)
      ) {
        return (
          <HotVacancyItem
            key={item.id}
            vacancy={item}
            title_btn={data.button_title}
            common_descr={common_data}
            to={`/${lang}/vacancies/vacancy/${item.vacancy_id.id}`}
          />
        );
      }
    });
    return (
      <SliderUniversal
        dotsPagination={true}
        numberOfSlides={1}
        children={vacancyCards}
      />
    );
  };

  const renderHotVacancyBlock = () => {
    return vacationList.map(
      (item) =>
        (item?.isHotVacancy
          && (item.vacancy_category.Category === chosenVacationCategory
          || chosenVacationCategory === showAllType)
          && (
            <HotVacancyItem
              key={item.id}
              vacancy={item}
              title_btn={data.button_title}
              common_descr={common_data}
              to={`/${lang}/vacancies/vacancy/${item.vacancy_id.id}`}
            />
      )  )
    );
  };

  return (
    <Layout>
      <SEO title="Vacancy" />
      <section
        className={`section page-vacancy ${!isMobile() ? 'container' : ''}`}
      >
        <div className="vacancy-container">
          <h1 className="pageTitle ">{data.page_title}</h1>

          <DeviceDetect.UniversalDesktop>
            <div className={!toggleBox ? 'vacancy-container-close' : 'row'}>
              {renderFilterVacancy()}
              <div
                onClick={handleClickMoreVacancy}
                className="vacancy-box-toggle"
              >
                {toggleBox ? (
                  <CloseImg className="icon" />
                ) : (
                  <OpenImg className="icon" />
                )}
              </div>
            </div>
          </DeviceDetect.UniversalDesktop>
          <DeviceDetect.Mobile>
            {renderVacancyFilterSlider()}
          </DeviceDetect.Mobile>
        </div>

        {showHotVacancies() && (
          <div className="full-width">
            <div className="vacations-wrap">
              <div className="container">
                <div className="vacations-in">
                  <h3 className="text-primary">
                    {data?.hot_vacancies || (
                      <Trans i18nKey="homePage.vacation.hotVacanciesTitle" />
                    )}
                  </h3>
                  <div className="vacation-list">
                    <DeviceDetect.UniversalDesktop>
                      {renderHotVacancyBlock()}
                    </DeviceDetect.UniversalDesktop>
                    <DeviceDetect.Mobile>
                      {renderHotVacancySlider()}
                    </DeviceDetect.Mobile>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {(!showHotVacancies() || chosenVacationCategory === showAllType) && (
          <div className="other-vacancies">
            <div className="vacations-wrap-other">
              <div className="container">
                <div className="vacations-in">
                  <div className="h1">{data.all_vacancy_title}</div>
                  <div className="vacation-list">
                    {vacationList?.map(
                      (item) =>
                        (item.isHotVacancy === null &&
                        (item.vacancy_category.Category === chosenVacationCategory
                          || chosenVacationCategory === showAllType)
                          && (
                            <div key={item.id} className="vacation-item">
                              <VacancyItem
                                vacancy={item}
                                url={`/${lang}/vacancies/vacancy/${item.vacancy_id.id}`}
                                common_descr={common_data}
                                title_btn={data.button_title}
                                to={`/${lang}/vacancies/vacancy/${item.vacancy_id.id}`}
                              />
                            </div>
                        ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </Layout>
  );
};

export default Vacancy;
