import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {renderIconFactory} from "./imageFactory";
import {useTranslation} from "react-i18next";

const typeAllCategory =  "All categories"

function isThisTypeOfVacancyExist(vacancy, typesActiveVacancies) {
  return vacancy?.title.Category === typeAllCategory || typesActiveVacancies.has(vacancy.title.Category);
}


const VacancyBox = ({ vacancy, typesActiveVacancies, setChosenVacancyType, chosenVacationType}) => {

  const { t } = useTranslation();
  const [background, setBackground] = useState(chosenVacationType === vacancy.title.Category  ? vacancy.background : "" )
  useEffect(()=>{
    if(chosenVacationType !== vacancy.title.Category ) setBackground('')
  },[chosenVacationType])

  const checkBox = useRef(null)

  const isExistThisTypeOfVacancy  =  isThisTypeOfVacancyExist(vacancy, typesActiveVacancies)
  const  classNames = () => {
    let classNames = "";
    if(vacancy?.title.Category === typeAllCategory){
      classNames += "getAllVacancy "
    }
    if(background) classNames += "withBackground ";
    return (classNames + (isExistThisTypeOfVacancy ? `vacancy-box` : 'vacancy-box-disable'))
  };

  const mouseBlockLeave = () => {
    if(chosenVacationType !== vacancy.title.Category) {
      setBackground('')
    }
  }

  const filterChange = () =>{
    if(isExistThisTypeOfVacancy){
      checkBox.current.checked = true
      setChosenVacancyType(vacancy.title.Category)
    }
  }

  const addBackground = () => {
    if(isExistThisTypeOfVacancy)
      setBackground(vacancy.background)
  }

  useEffect(() => {
    if(chosenVacationType === vacancy.title.Category) addBackground();
  }, [chosenVacationType, vacancy])
  if(!vacancy) return  <></>
  
  return (
       <div
         onClick={filterChange}
         className={classNames()}
         onMouseEnter={addBackground}
         onMouseLeave={mouseBlockLeave}
         style={{background: `${background}`}}
         id={ chosenVacationType === vacancy.title.Category  ? "chosenTypeVacancy" : ""}
       >
         {renderIconFactory({key :vacancy.class, isActive: isExistThisTypeOfVacancy})}
         <input name="vacancy_type" type="radio" ref={checkBox} className={"vacancy-type-checkbox"} />
         <span className="title">
          {(vacancy?.title.Category === typeAllCategory) ? t('homePage.vacation.allVacationsLink') : vacancy?.title.Category}
         </span>
       </div>
   );
};

VacancyBox.propTypes = {
  vacancy: PropTypes.shape({
    title: PropTypes.object,
    icon: PropTypes.string,
    class: PropTypes.string,
  }).isRequired,
  hot: PropTypes.bool,
  typesActiveVacancies: PropTypes.object,
  setChosenVacancyType: PropTypes.func,
  chosenVacationType: PropTypes.string
};

export default VacancyBox;
