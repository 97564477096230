import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const HotVacancyItem = ({ vacancy, to, title_btn }) => {
  return (
    <div className="vacation-item">
      <div className="vacation">
        <div className="d-flex align-items-start">
          <div className="flex-grow-1">
            <h2>
              <Link className="profi-link" to={to}>
                {vacancy.title}
              </Link>
            </h2>
            <div className="vacation-subtitle">
              {vacancy.address} &nbsp; {vacancy.experience}
            </div>
          </div>
          <div className="flex-grow-0 ml-3">
            <div className="vacation-icon">
              <span role="img" aria-label="vacation">
                🔥
              </span>
            </div>
          </div>
        </div>
        <div className="vacation-text">{vacancy.pre_description}</div>
        <div className="d-flex align-items-center justify-content-between vacation-bottom">
          <div>{vacancy.date}</div>
          <Link to={to} className="btn btn-primary rounded-pill">
            {title_btn}
          </Link>
        </div>
      </div>
    </div>
  );
};

HotVacancyItem.propTypes = {
  vacancy: PropTypes.shape({
    title: PropTypes.string,
    address: PropTypes.string,
    pre_description: PropTypes.string,
    date: PropTypes.string,
    button_title: PropTypes.string,
  }).isRequired,
  to: PropTypes.string,
};

export default HotVacancyItem;
