import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const VacancyItem = ({ vacancy, title_btn, url, to }) => {
  return (
    <div className="vacation">
      <div className="d-flex align-items-start">
        <div className="flex-grow-1">
          <Link to={url} className="vacancy-title">
            {vacancy.title}
          </Link>
          <div className="vacation-subtitle">
            {vacancy.address} &nbsp; {vacancy.experience}
          </div>
        </div>
        <div className="flex-grow-0 ml-3">
          {vacancy.isHotVacancy && (
            <div className="vacation-icon">
              <span role="img" aria-label="icon">
                🔥
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="vacation-text">{vacancy.pre_description}</div>
      <div className="d-flex align-items-center justify-content-between vacation-bottom">
        <div>{vacancy.date}</div>
        <div className="d-flex align-items-center justify-content-between vacation-bottom">
          <Link to={to} className="btn btn-primary rounded-pill">
            {title_btn}
          </Link>
        </div>
      </div>
    </div>
  );
};

VacancyItem.propTypes = {
  vacancy: PropTypes.shape({
    title: PropTypes.string,
    address: PropTypes.string,
    pre_description: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
  hot: PropTypes.bool,
};

export default VacancyItem;
